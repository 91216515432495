import { useIsLoggedIn, useNavigationItems, useWhoAmIQuery } from '../utils/hooks'
import { useRoutes } from '../constants/routes.ts'
import Person from './icons/person.tsx'
import RoundedImage from './roundedImage.tsx'

type Props = {
  userPhotoUrl: string | null
}

export default function Navigation({ userPhotoUrl }: Props) {
  const nav = useNavigationItems()
  const routes = useRoutes()
  const { data } = useWhoAmIQuery()
  const isLoggedIn = useIsLoggedIn()

  return (
    <div className="hidden md:flex">
      <div data-cy="navigation-pane" className="align-end flex flex-row items-center gap-2">
        {nav.map((item, index) => (
          <a
            href={item.to}
            data-cy={item.key}
            key={index}
            className="py-2 px-4 !no-underline text-gray-600 capitalize"
            rel="prefetch">
            <span>{item.label}</span>
          </a>
        ))}
        <a href={data ? routes.profile() : routes.auth()} className="mr-2" data-cy="auth.signIn">
          {isLoggedIn ? (
            <RoundedImage
              src={data?.whoAmI.picture || userPhotoUrl}
              size={50}
              data-cy="navigation.profile"
            />
          ) : (
            <Person data-cy="auth.signIn" />
          )}
        </a>
      </div>
    </div>
  )
}
